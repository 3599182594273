import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        component: () => import('@/layout/ExpiredSettingsLayout.vue'),
        redirect: '/pengaturan/masa-expired',
        children: [
            {
                path: '/pengaturan/masa-expired',
                name: 'ExpiredSettingsView',
                component: () => import('@/views/ExpiredSettingsView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
